import * as React from "react";
import "../style/style.scss";
import "../../node_modules/aos/dist/aos.css";
import { Container, Row, Col, Modal, CloseButton } from "react-bootstrap";
import FranzStriebelPortrait from "../images/1958-franz-striebel.jpg";
import HannoverMesse from "../images/1968-hannover-messe.jpg";
import Polyrastsystem from "../images/1974-polyrasersystem.png";
import WandUndStandverteiler from "../images/1984-wand-und-standverteiler.png";
import Schnellmontagesystem from "../images/1988-schnellmontagesystem.jpg";
import Versandtzentrum from "../images/1995-versandtzentrum.png";
import TrilineRSchaltSchranksystem from "../images/2003-triline-r-schalt-schranksystem.png";
import CD from "../images/cd.png";
import PlanoDiskette from "../images/plano-diskette.png";
import ReihenschalterschrankProgramm from "../images/reihenschalterschrank-programm.png";
import Jubilaeum25 from "../images/1983-25-jahre-striebel-und-john-logo.png";
import Jubilaeum50 from "../images/2008-50-jahre-striebel-john.png";
import AOS from "aos";

// markup
class IndexPage extends React.Component {
  constructor() {
    super();
    this.state = {
      openImagebox: false,
      image: null,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      AOS.init();
    }, 100);
  }

  render() {
    const elements = [
      {
        year: 1958,
        titel: "Gründung",
        text: (
          <>
            von Striebel & John
            <br />
            Firmengründer: Franz Striebel
          </>
        ),
        img: FranzStriebelPortrait,
      },
      {
        year: 1959,
        text: "Striebel & John liefert fabrikfertige Stromkreisverteiler mit geschlossenem Putzkasten und herausnehmbarem Geräteträger",
      },
      {
        year: 1963,
        text: "Entwicklung der Berührungsschutzkappe für E27 E33 Elemente - Bau einer 400 qm großen Halle",
      },
      {
        year: 1965,
        text: "Der Vertrieb wird erstmals ber die Landesgrenzen Baden-Württembergs ausgedehnt",
      },
      {
        year: 1966,
        text: "Entwicklung des wegweisenden Normfeldprogramms",
      },
      {
        year: 1968,
        text: "Striebel & John stellt zum ersten Mal auf der Hannover Messe aus",
        img: HannoverMesse,
      },
      {
        year: 1969,
        text: "Bau des ersten Gebäudes, im Klammsbosch",
      },
      {
        year: 1973,
        text: (
          <>
            Einführung des UK Stromverteilers für zwei Einbautiefen
            <br />
            (1970 - 1980 Markführer)
            <br />
            <br />
            Am Standort Sasbach wird die Oberflächentechnik OFT geprüft
          </>
        ),
      },
      {
        year: 1974,
        text: "Das Polyrastsystem, ein stabiles Polyestergehäuseprogramm, ergänzt die Striebel & John Produktpalette",
        img: Polyrastsystem,
      },
      {
        year: 1977,
        text: "Reihenschaltschrankprogramm RSN-System wird in die Fertigung aufgenommen",
      },
      {
        year: 1983,
        titel: "25 Jahre Striebel & John",
        text: "230 Mitarbeiter",
        img: Jubilaeum25,
      },
      {
        year: 1984,
        text: "Wand- und Standverteilerreihe FS, C und G werden auf dem Markt angeboten",
        img: WandUndStandverteiler,
      },
      {
        year: 1985,
        text: (
          <>
            Vorstellung der neuen Stromkreisverteiler
            <br />
            UK Reihe 200
          </>
        ),
      },
      {
        year: 1986,
        text: "Der Anreihverteiler erhält eine Auszeichnung für gutes Design – das IF-Zeichen",
      },
      {
        text: "Entwicklung des Reihenschrank-Programms XA geerdet und XS schutzisoliert",
        img: ReihenschalterschrankProgramm,
      },
      {
        year: 1988,
        text: "Erster Hersteller von EDF-Schnellmontagesystemen",
        img: Schnellmontagesystem,
      },
      {
        text: "Vorstellung der ersten Planungs- und Projektierungssoftware – Plano",
        img: PlanoDiskette,
      },
      {
        year: 1990,
        text: "Vorstellung der neuen Stromkreisverteiler UK Reihe 400",
      },
      {
        year: 1992,
        text: (
          <>
            Der Produktionsstandort wurde um 2500 qm erweitert und weitere
            flexible Fertigungseinrichtungen angeschafft
            <br />
            <br />
            Gründung eines modernen Kunststoffspritzbetriebs
          </>
        ),
      },
      {
        year: 1993,
        text: "Striebel & John wird in die ABB Firmengruppe aufgenommen",
      },
      {
        year: 1995,
        text: (
          <>
            Das hochmoderne Lager und Versandzentrum wird in Betrieb genommen
            <br />
            <br />
            Zertifizierung gemäß internationaler Maßstäbe nach DIN EN ISO 9001
          </>
        ),
        img: Versandtzentrum,
      },
      {
        year: 1996,
        text: "Gründung der ASJ ABB STOTZ-KONTAKT / Striebel & John Vertriebsgesellschaft",
      },
      {
        year: 1998,
        text: "Vorstellung der neuen Stromkreisverteiler der UK Reihe 500 zur Hannover Messe",
      },
      {
        year: 1999,
        text: "Vorstellung des neuen Haubenverteilers A300",
      },
      {
        year: 2000,
        text: "Markteinführung der neuen Kleinverteiler und TSK-Verteilersysteme",
      },
      {
        year: 2001,
        text: "Bezug des neuen Schulungs- und Verwaltungszentrums am Fuchsgraben",
      },
      {
        year: 2002,
        text: "Markteinführung der N/PE Quick-Steckschiene zur Light+Building, Frankfurt",
      },
      {
        year: 2003,
        text: "Markteinführung des TriLine-R Reihen-Schaltschranksystems",
        img: TrilineRSchaltSchranksystem,
      },
      {
        year: 2004,
        text: "Brandschutzverteiler als Wand- und Standverteiler werden im Produktsortiment vorgestellt",
      },
      {
        year: 2005,
        text: (
          <>
            StriePlan 3.0 Planungs- und Kalkulationssoftware
            <br />
            Neu: mit Stromlaufplan SLP
          </>
        ),
        img: CD,
      },
      {
        year: 2006,
        text: "Typgeprüfte Reihenschaltschränke bis 4000 A (TSK) und die neuen eHZ-Zählerschränke werden auf der Light+Building vorgestellt",
      },
      {
        year: 2008,
        titel: (
          <>
            50 Jahre
            <br />
            Striebel & John
          </>
        ),
        img: Jubilaeum50,
      },
    ];

    const handleShow = (e) => {
      this.setState({ openImagebox: true, image: e.target.src });
    };
    const handleClose = (e) => {
      this.setState({ openImagebox: false });
    };
    return (
      <>
        <Modal
          size="lg"
          show={this.state.openImagebox}
          onHide={handleClose}
          centered
          fullscreen={true}
        >
          <CloseButton className="ms-auto" onClick={handleClose}/>
          <Modal.Body>
            <img src={this.state.image} />
          </Modal.Body>
        </Modal>
        <main>
          <Container>
            <section className="timeline">
              <Row>
                <Col xs={12}>
                  <ul className="timeline-list">
                    {elements.map((e, i) => {
                      return (
                        <div
                          key={i}
                          className={`timeline-item`}
                          data-aos={i % 2 ? "fade-left" : "fade-right"}
                          data-aos-anchor-placement="center-bottom"
                          data-aos-once="true"
                        >
                          <div className="timeline-dot">{e.year}</div>

                          <div
                            className={`timeline-content ${
                              i % 2 ? "right" : "left"
                            }`}
                          >
                            {e.titel ? <h2>{e.titel}</h2> : null}
                            {e.text ? <p>{e.text}</p> : null}
                            {e.img ? (
                              <img
                                style={{ width: "10rem" }}
                                className={
                                  i % 2 ? "ms-auto" : "ms-auto me-md-0"
                                }
                                src={e.img}
                                alt={`img-${i}`}
                                onClick={handleShow}
                              />
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            </section>
          </Container>
        </main>
      </>
    );
  }
}

export default IndexPage;
